import React from 'react'

import { Grid, Item, Row } from '../components/flexGrid'
import Image from '../components/image'

const BioPage = ({ pageContext }) => {
  const { content, image } = pageContext

  return (
    <Grid>
      <Row>
        <Item w={7}>
          <p dangerouslySetInnerHTML={{ __html: content }} />
        </Item>
        <Item w={5}>
          <Image src={image} />
        </Item>
      </Row>
    </Grid>
  )
}

export default BioPage
